import React from 'react';
import classNames from 'classnames';

import ComponentMapper from 'cms/ComponentMapper';
import Layout from 'components/Layout';
import { commonDefaultProps, commonPropTypes } from 'components/CmsContent/CmsContent.types';

import styles from 'cms/CmsComponent/CmsComponent.scss';
import FBWidget from 'utils/FBWidget/FBWidget';

const CmsComponent = ({
  className,
  component,
  componentsWithPresetMargin,
  fullWidthComponents,
  noContainerComponents,
  noSpacings,
}) => {
  const mappedComponents = <ComponentMapper content={component} />;
  const usesContainer = !noContainerComponents?.includes(component?.restType);

  /**
   * We're able to reduce complexity, as mappedComponents may return null anyhow, so no extra-checking necessary.
   * Also, function now fails early without overhead.
   */
  if (!usesContainer) {
    return mappedComponents;
  }

  const isFullWidthComponent = fullWidthComponents?.includes(component?.restType);
  const isPresetMarginComponent = componentsWithPresetMargin?.includes(component?.restType);
  const isSeparatorComponent = component?.position === 'template-bottom';

  const containerAttributes = {
    className: classNames(className, {
      [styles.fullWidthComponent]: isFullWidthComponent,
      [styles.separator]: isSeparatorComponent,
      [styles.noSpacings]: noSpacings,
    }),
    key: component?.id,
  };

  /**
   * Fixes "attribute margin is not allowed on div".
   */
  if (!isFullWidthComponent && component?.restType !== 'CmsAdviceComponent') {
    // applies custom margin if required, fallback is default margin
    containerAttributes.margin = isPresetMarginComponent ? 'content' : 'section';
  } else {
    Object.assign(containerAttributes, { 'data-testid': 'fullWidthComponent' });
  }
  const Element = isFullWidthComponent ? 'div' : Layout;

  let fbWidget = null;

  // The below is currently only "test", if this is a success, then we would add this
  // to component props
  if (
    component?.cmsPageCode === 'kapcsolatfelvetel' &&
    component?.contentCatalog === 'moemax.hu-content'
  ) {
    fbWidget = <FBWidget />;
  }

  return (
    <Element {...containerAttributes}>
      {mappedComponents}
      {fbWidget}
    </Element>
  );
};

CmsComponent.propTypes = commonPropTypes;
CmsComponent.defaultProps = commonDefaultProps;

export default CmsComponent;
