import PropTypes from 'prop-types';
import { Types as commonTypes } from 'types';

const availableKeys = ['breadcumbs', 'seoData', 'contentSlots'];

export const commonPropTypes = {
  /** graphql query object */
  cmsPageData: commonTypes.cmsPageData,
  /** Which content slots to render depending on provided content slots names. All if empty */
  contentSlot: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /** components with modified margin different from default, see Layout component */
  componentsWithPresetMargin: PropTypes.arrayOf(PropTypes.string),
  /** component to be used */
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Which components should get rendered. All if empty */
  components: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  /** graphql query object for pdp specific data */
  cmsProductSpecificData: commonTypes.cmsProductSpecificData,
  /** @ignore */
  className: PropTypes.string,
  /** an array containing components which should be rendered on fullwidth */
  fullWidthComponents: PropTypes.arrayOf(PropTypes.string),
  /** id of the content slot */
  id: PropTypes.string,
  /** flag for product specific content */
  isProductSpecific: PropTypes.bool,
  /** If true, it removes margin and padding from the component */
  noSpacings: PropTypes.bool,
  /** an array containing components which should be renderer without extra container */
  noContainerComponents: PropTypes.arrayOf(PropTypes.string),
  /** flag for controlling retrieving of product cms content in case when not needed */
  noProductData: PropTypes.bool,
  /** code of the product to pass to children */
  productCode: PropTypes.string,
  /** If set, shows loading indicator */
  showLoading: PropTypes.bool,
  /** graphql query object */
  templateData: commonTypes.cmsTemplateData,
};

export const commonDefaultProps = {
  className: null,
  cmsPageData: {},
  component: {},
  components: null,
  componentsWithPresetMargin: ['CmsHeadingComponent'],
  cmsProductSpecificData: {},
  contentSlot: null,
  fullWidthComponents: [
    'CmsAdviceComponent',
    'CmsFooterRibbonComponent',
    'CmsHeroComponent',
    'CmsSuperBillboardSliderContainer',
  ],
  id: null,
  isProductSpecific: false,
  noContainerComponents: [],
  noMarginComponents: ['CmsHeadingComponent'],
  noSpacings: false,
  noProductData: false,
  productCode: null,
  showLoading: false,
  templateData: {},
};

export const propTypes = {
  ...commonPropTypes,
  /** function that will be called with metadata */
  /* @deprecated */
  children: PropTypes.func,
  /** graphql query object */
  cmsProductContentData: commonTypes.cmsProductContentData,
  /** if disable cache or not */
  disableCache: PropTypes.bool,
  /** All selected filters */
  filters: commonTypes.filters,
  /** If set, don't show error page */
  hideError: PropTypes.bool,
  /** one or multiple keys that values will be returned */
  keys: PropTypes.oneOfType([
    PropTypes.oneOf(availableKeys),
    PropTypes.arrayOf(PropTypes.oneOf(availableKeys)),
  ]),
  /** If true, code is used as a product code to load product related CMS components */
  loadProductContent: PropTypes.bool,
  /** an array of components where no extra margin should be rendered */
  noMarginComponents: PropTypes.arrayOf(PropTypes.string),
  /** function that will be called when page content is loaded */
  onPageLoad: PropTypes.func,
  /** The code of the content page in the database */
  pageCode: PropTypes.string,
  /** Redirect CMS page when a error is thrown */
  redirectOnError: PropTypes.bool,
  /** Write helmet seo data */
  seo: PropTypes.bool,
  /** Id of the template that is loading */
  templateId: PropTypes.string,
  /** position of the content in the template */
  templatePosition: PropTypes.string,
};

export const defaultProps = {
  ...commonDefaultProps,
  children: null,
  cmsProductContentData: {},
  disableCache: false,
  filters: [],
  hideError: false,
  keys: null,
  loadProductContent: false,
  onPageLoad: null,
  pageCode: null,
  redirectOnError: false,
  seo: false,
  templateId: null,
  templatePosition: null,
};
