import React, { useContext, useEffect, useRef } from 'react';
import track from 'react-tracking';
import { useQuery } from '@apollo/client';
import { isObjectEmpty } from '@xxxlgroup/hydra-utils/common';
import { tagComponent } from 'utils/tracking/tracking';
import BaseCmsContent from 'components/CmsContent/components/BaseCmsContent';
import { SmartEditContext } from 'components/SmartEditHandling';
import {
  CMSCONTENT_PAGE_QUERY,
  CMSCONTENT_PRODUCT_QUERY,
  CMSCONTENT_PRODUCT_SPECIFIC_QUERY,
  TEMPLATE_PAGE_QUERY,
} from 'components/CmsContent/CmsContent.query';
import { propTypes, defaultProps } from 'components/CmsContent/CmsContent.types';

const getFetchPolicy = (disableCache) => ({
  fetchPolicy: disableCache ? 'network-only' : 'cache-first',
});

const addFilters = (filters) => (filters?.length ? { filters: JSON.stringify({ filters }) } : {});

const CmsContent = (props) => {
  const {
    disableCache,
    filters,
    isProductSpecific,
    noProductData,
    pageCode,
    productCode,
    templateId,
    templatePosition,
    onPageLoad,
  } = props;
  const { isInSmartEdit } = useContext(SmartEditContext);
  const initialLoadedData = useRef({});

  const {
    data: cmsPageData = initialLoadedData.current,
    loading: CmsPageLoading,
    refetch: refetchPageData,
    error: pageError,
  } = useQuery(CMSCONTENT_PAGE_QUERY, {
    skip: !pageCode || isProductSpecific,
    variables: {
      code: pageCode,
      cmsTicketId: null,
      keepCache: null,
      ...addFilters(filters),
    },
    ...getFetchPolicy(disableCache),
  });

  const { data: templateData = {}, refetch: refetchTemplateData } = useQuery(TEMPLATE_PAGE_QUERY, {
    skip: !templateId || !templatePosition,
    variables: { templateId, position: templatePosition, cmsTicketId: null },
    ...getFetchPolicy(disableCache),
  });

  const { data: cmsProductContentData = {}, error: productError } = useQuery(
    CMSCONTENT_PRODUCT_QUERY,
    {
      skip: noProductData || !productCode,
      variables: { code: productCode, cmsTicketId: null },
      ...getFetchPolicy(disableCache),
    },
  );

  const {
    data: cmsProductSpecificData = {},
    refetch: refetchProductSpecificData,
    error: productSpecificError,
  } = useQuery(CMSCONTENT_PRODUCT_SPECIFIC_QUERY, {
    skip: !productCode || !pageCode || !isProductSpecific,
    variables: { code: productCode, pageCode, cmsTicketId: null },
    ...getFetchPolicy(disableCache),
  });

  const { getTemplatePage, isInSmartEdit: templateInSmartEdit } = templateData;

  useEffect(() => {
    if (onPageLoad && !initialLoadedData.current.getContentPage) {
      onPageLoad(CmsPageLoading);
    }
    initialLoadedData.current = { ...cmsPageData };
  }, [CmsPageLoading, pageCode, onPageLoad, cmsPageData]);

  const isPageEligibleForSmartEdit = () => {
    // header and footer shouldn't be considered as pages in smartedit
    const pageBlocklist = ['headerpage', 'footpage'];

    return (pageCode && !pageBlocklist.includes(pageCode)) || !!templateId;
  };

  const addSmarteditPageContract = (pageContract) => {
    if (pageContract) {
      document.body.classList = productCode
        ? `${pageContract} smartedit-product-code-${productCode}`
        : pageContract;
    }
    window.smartedit = window.smartedit || {};

    if (!isObjectEmpty(cmsProductSpecificData)) {
      window.refetchProductSpecificData = () => {
        refetchProductSpecificData({
          code: productCode,
          pageCode,
        });
      };
    }

    if (!isObjectEmpty(getTemplatePage)) {
      window.refetchTemplateData = () => {
        refetchTemplateData({
          templateId,
          position: templatePosition,
        });
      };
    }

    if (!isObjectEmpty(cmsPageData)) {
      window.refetchPageData = () => {
        refetchPageData({
          code: pageCode,
        });
      };
    }

    window.smartedit.renderComponent = () => {
      window.refetchTemplateData && window.refetchTemplateData();
      window.refetchPageData && window.refetchPageData();
      window.refetchProductSpecificData && window.refetchProductSpecificData();
    };

    window.smartedit.reprocessPage = () => {};
  };

  const { getContentPage } = cmsPageData;

  const cmsContentInSmartEdit =
    isInSmartEdit &&
    (getContentPage?.smartedit || templateInSmartEdit || !isObjectEmpty(cmsProductSpecificData)) &&
    isPageEligibleForSmartEdit();

  if (cmsContentInSmartEdit) {
    addSmarteditPageContract(getContentPage?.smartedit?.pageContract);
  }

  return (
    <BaseCmsContent
      {...props}
      cmsPageData={{ ...cmsPageData, error: pageError }}
      templateData={templateData}
      cmsProductContentData={{ ...cmsProductContentData, error: productError }}
      cmsProductSpecificData={{ ...cmsProductSpecificData, error: productSpecificError }}
    />
  );
};

CmsContent.propTypes = propTypes;
CmsContent.defaultProps = defaultProps;

export default track(tagComponent('CmsContent'))(CmsContent);
