import React, { FC } from 'react';

import Helmet from 'components/Helmet';

interface CmsHelmetProps {
  seoData?: {
    title?: string | null;
    keywords?: string | null;
    canonicalUrl?: string | null;
    description?: string | null;
    baseStoreRelatedItems?: Array<Record<string, unknown>>;
    noFollow?: boolean | null;
    noIndex?: boolean | null;
  };
}

const CmsHelmet: FC<CmsHelmetProps> = ({ seoData }) => {
  if (!seoData) {
    return null;
  }

  const {
    title = '',
    keywords = '',
    canonicalUrl = '',
    description = '',
    baseStoreRelatedItems = [],
    noFollow = false,
    noIndex = false,
  } = seoData;

  return (
    <Helmet
      title={title}
      keywords={keywords}
      canonicalUrl={canonicalUrl}
      description={description}
      hreflangData={baseStoreRelatedItems}
      noFollow={noFollow}
      noIndex={noIndex}
    />
  );
};

export default CmsHelmet;
