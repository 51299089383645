import React from 'react';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';

import { filterComponentsToRender as filterComponents } from 'components/CmsContent/utils/utils';

import { commonDefaultProps, commonPropTypes } from 'components/CmsContent/CmsContent.types';
import CmsComponent from 'cms/CmsComponent';

export const CmsComponentList = (props) => {
  const { components, getProductContent, productCode } = props;

  if (isArrayEmpty(getProductContent?.components)) {
    return null;
  }

  const componentsToRender = filterComponents(components, getProductContent);

  return componentsToRender.map((component) => (
    <CmsComponent
      key={component.uid}
      {...props}
      component={{
        productCode,
        ...component,
      }}
    />
  ));
};

CmsComponentList.propTypes = commonPropTypes;
CmsComponentList.defaultProps = commonDefaultProps;

export default CmsComponentList;
