import React, { useContext } from 'react';
import classNames from 'classnames';
import { Loading } from '@xxxlgroup/hydra-ui-components';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';

import ContentSlot from 'components/CmsContent/components/ContentSlot';
import { SmartEditContext } from 'components/SmartEditHandling';
import { commonDefaultProps, commonPropTypes } from 'components/CmsContent/CmsContent.types';
import { filterUsedContentSlots, isLoading } from 'components/CmsContent/utils/utils';

import styles from 'components/CmsContent/components/ContentSlots/ContentSlots.scss';

const ContentSlots = (props) => {
  const {
    cmsPageData,
    cmsPageData: { getContentPage },
    cmsProductContentData,
    components,
    contentSlot,
    showLoading,
    templateData,
    templateData: { getTemplatePage },
    cmsProductSpecificData,
    cmsProductSpecificData: { getProductSpecificContent },
  } = props;
  const { isInSmartEdit } = useContext(SmartEditContext);

  if (
    isArrayEmpty(getContentPage?.contentSlots) &&
    isArrayEmpty(getTemplatePage?.contentSlots) &&
    isArrayEmpty(getProductSpecificContent?.contentSlots)
  ) {
    return null;
  }

  if (
    isLoading(cmsProductContentData, cmsPageData, templateData, showLoading, cmsProductSpecificData)
  ) {
    return <Loading />;
  }

  let usedContentSlots =
    getContentPage?.contentSlots ||
    getTemplatePage?.contentSlots ||
    getProductSpecificContent?.contentSlots;

  usedContentSlots = filterUsedContentSlots(usedContentSlots, contentSlot);

  return usedContentSlots.map((singleContentSlot) => {
    /* Render content slot and wrap it with SmartEdit contracts. They are later used by SmartEdit to render the
     * overlay used for editing/moving/adding. */
    const { components: slotComponents = [], uid } = singleContentSlot;
    const [{ restType } = { restType: '' }] = slotComponents;
    const shouldHaveTopMargin =
      restType === 'CmsHeadingComponent' || restType === 'CmsFormComponent';
    const wrapperClassName = shouldHaveTopMargin ? styles.headerSpacing : null;

    // Add the restType to the content slot, used for SmartEdit to determine the location of the content slot
    const contentSlotData = {
      ...props,
      ...singleContentSlot,
      className: classNames(props.className, wrapperClassName),
      allowedComponents: components,
      restType: 'ContentSlot',
      isInSmartEdit,
    };

    return <ContentSlot {...contentSlotData} key={uid} />;
  });
};

ContentSlots.propTypes = commonPropTypes;
ContentSlots.defaultProps = commonDefaultProps;

export default ContentSlots;
