import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import { tagComponent } from 'utils/tracking/tracking';
import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';
import withSmartEdit from 'cms/WithSmartEdit';

import { commonDefaultProps, commonPropTypes } from 'components/CmsContent/CmsContent.types';
import CmsComponent from 'cms/CmsComponent';

const ContentSlot = (props) => {
  const {
    allowedComponents,
    catalogVersion,
    className,
    contentCatalog,
    components,
    cmsPageData: { getContentPage },
    position,
    productCode,
    isInSmartEdit,
    imageSizes,
  } = props;

  let componentsToRender = components;

  if (!isArrayEmpty(allowedComponents) && !getContentPage?.seoData?.noIndex) {
    const filterComponents = (component) => allowedComponents.includes(component.restType);
    componentsToRender = componentsToRender.filter(filterComponents);
  }

  return isArrayEmpty(componentsToRender) ? null : (
    <div className={className}>
      {componentsToRender.map((component) => (
        <CmsComponent
          {...props}
          key={component.uid}
          component={{
            cmsPageCode: getContentPage?.code || null,
            productCode,
            ...component,
            catalogVersion,
            contentCatalog,
            isInSmartEdit,
            imageSizes,
            position,
          }}
        />
      ))}
    </div>
  );
};

ContentSlot.propTypes = {
  ...commonPropTypes,
  /** Which components should get rendered. All if empty */
  allowedComponents: PropTypes.arrayOf(PropTypes.string),
};
ContentSlot.defaultProps = {
  ...commonDefaultProps,
  allowedComponents: [],
};

export default track(tagComponent('ContentSlot'))(withSmartEdit(ContentSlot));
