import React from 'react';

import ErrorHandler from 'components/ErrorHandler';
import CmsHelmet from 'components/CmsHelmet';
import CmsComponentList from 'components/CmsContent/components/CmsComponentList';
import ContentSlots from 'components/CmsContent/components/ContentSlots';

import { propTypes, defaultProps } from 'components/CmsContent/CmsContent.types';
import { renderChildren } from 'components/CmsContent/utils/utils';

const BaseCmsContent = (props) => {
  const {
    children,
    cmsPageData,
    cmsPageData: { getContentPage },
    cmsPageData: { error: pageError },
    cmsProductContentData: { error: productError },
    hideError,
    keys,
    pageCode,
    productCode,
    cmsProductSpecificData: { error: productSpecificError },
    seo,
    templateData: { error: templateError },
    templateId,
    templatePosition,
  } = props;
  const hasError = pageError || productError || templateError || productSpecificError;

  const renderContent = () => {
    if (templatePosition && templateId) {
      return <ContentSlots {...props} />;
    }

    if (pageCode === productCode || !pageCode) {
      return <CmsComponentList {...props} />;
    }

    return <ContentSlots {...props} />;
  };

  if (hasError) {
    return !hideError && <ErrorHandler error={hasError} />;
  }

  return (
    <>
      {children ? renderChildren(children, cmsPageData, getContentPage, keys) : renderContent()}
      {seo && getContentPage?.seoData && <CmsHelmet seoData={getContentPage.seoData} />}
    </>
  );
};

BaseCmsContent.propTypes = propTypes;
BaseCmsContent.defaultProps = defaultProps;

export default BaseCmsContent;
