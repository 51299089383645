import useFeatureFlagsEnabled from 'hooks/useFeatureFlagsEnabled';
import React, { useEffect } from 'react';

const FBWidget: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isFeatureOn = useFeatureFlagsEnabled('poseidon.bobblo.enabled');
  useEffect(() => {
    if (!isFeatureOn) {
      return;
    }
    const chatbox = document.getElementById('fb-customer-chat') as HTMLDivElement; // This is needed for TS

    // The below are currently hardcoded to moemax.hu - will be added to mandant config in the future
    chatbox.setAttribute('page_id', '649157261900813');
    chatbox.setAttribute('attribution', 'biz_inbox');

    (window as any).fbAsyncInit = () => {
      (window as any).FB.init({
        xfbml: true,
        version: 'v14.0',
      });
    };

    // eslint-disable-next-line func-names
    (function (d, s, id) {
      let js;
      const fjs = d.getElementsByTagName(s)[0];
      // eslint-disable-next-line prefer-const
      js = d.createElement(s) as HTMLScriptElement;
      js.id = id;
      js.src = 'https://connect.facebook.net/hu_HU/sdk/xfbml.customerchat.js';
      (fjs as any).parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, [isFeatureOn]);

  if (!isFeatureOn) {
    return null;
  }
  return (
    <>
      <div id="fb-root" />
      <div id="fb-customer-chat" className="fb-customerchat" />
    </>
  );
};

export default FBWidget;
