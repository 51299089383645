import { isArrayEmpty } from '@xxxlgroup/hydra-utils/common';

export const isLoading = (cmsProductContentData, cmsPageData, templateData, showLoading) =>
  (showLoading && cmsProductContentData?.loading) || cmsPageData?.loading || templateData?.loading;

export const renderChildren = (children, cmsPageData, getContentPage, keys) => {
  const { getContentPage: { breadcumbs = [], seoData = {}, contentSlots = [] } = {} } =
    cmsPageData || {};

  const data = { breadcumbs, seoData, contentSlots };

  return children(Array.isArray(keys) ? keys.map((key) => data[key]) : data[keys]);
};

export const filterComponentsToRender = (components, parentContent) => {
  let componentsToRender = [...parentContent.components];

  if (!isArrayEmpty(components)) {
    const restTypes = new Set(componentsToRender.map((component) => component.restType));
    componentsToRender = [
      ...componentsToRender,
      ...components.filter((component) => component.restType && !restTypes.has(component.restType)),
    ];
  }

  return componentsToRender;
};

export const filterUsedContentSlots = (usedContentSlots, contentSlot) => {
  if (contentSlot) {
    let contentSlotsToRender = contentSlot;
    const filterContentSlots = (contentSlots) => (contentSlotProp) =>
      contentSlots.includes(contentSlotProp.position);
    if (!Array.isArray(contentSlot)) {
      contentSlotsToRender = [contentSlot];
    }

    return usedContentSlots.filter(filterContentSlots(contentSlotsToRender));
  }

  return usedContentSlots;
};
